  /* ===============================  Code aus der app.js / Layout  =============================== */

  function ScreenSetup(){
    let screen = window.innerWidth;
    let controller = new ScrollMagic.Controller();
  
    if (screen < 768) {
      // console.log('screen is mobile');
      document.querySelector('body').classList.remove('desktop');
      document.querySelector('body').classList.add('mobile');
      // controller.destroy(true);
      if (controller) {
        controller.destroy(true);
        controller = new ScrollMagic.Controller();
      }
  
    } else if (screen >= 768) {
      // console.log('screen is desktop');
  
      document.querySelector('body').classList.remove('mobile');
      document.querySelector('body').classList.add('desktop');
      // controller.destroy(true);
      if (controller) {
        controller.destroy(true);
        controller = new ScrollMagic.Controller();
      }
    }
  
    let introVideoWrapper = document.querySelector(".intro_video_wrapper");
    let introVideoOverlay = document.querySelector(".intro_video_wrapper .video_overlay");
    let introVideo = document.querySelector(".intro_video_wrapper .video_bg");
  
    // Scene Section Intro
    if (screen >= 768) {
      const sectionIntro = document.querySelector("#intro section.intro");
      const introImage = sectionIntro.querySelector(".intro_img");
      
      const tweenIntro = new TimelineMax();
  
      tweenIntro.add(TweenMax.fromTo(introImage, 1, {
        opacity: 1,
        scale: 1,
      }, {
        opacity: 0,
        scale: 0.8,
        ease: Power1.easeInOut,
      }))
      .add(TweenMax.fromTo(sectionIntro, 1, {
        opacity: 1,
      }, {
        opacity: 0,
        ease: Power1.easeInOut,
      }))
      .add(TweenMax.to("header .logo", 1, {
        opacity: 1,
        ease: Power1.easeInOut,
      }));
  
      new ScrollMagic.Scene({
        triggerElement: sectionIntro,
        // duration: window.innerHeight,
        duration: '90%',
        triggerHook: 0, // 0-top, 0.5-center, 1-bottom
      })
      .setClassToggle("#intro", "active")
      .setTween(tweenIntro)
      // .addIndicators()
      .setPin(sectionIntro, {
        pushFollowers: false
      })
      // .setPin(sectionIntro)
      .addTo(controller);
    }
  
    if (screen < 768) {
      const sectionIntro = document.querySelector("#intro section.intro .section_con");
      const introImage = sectionIntro.querySelector(".intro_img");
      const introScrollIcon = sectionIntro.querySelector(".scroll-icon");
      
      const tweenIntro = new TimelineMax();
      tweenIntro.add(TweenMax.fromTo(introScrollIcon, 0.5, {
        opacity: 1,
      }, {
        opacity: 0,
        ease: Power1.easeInOut,
      }))
      tweenIntro.add(TweenMax.fromTo(introImage, 1, {
        opacity: 1,
        scale: 1,
      }, {
        opacity: 0,
        scale: 0.8,
        ease: Power1.easeInOut,
      }))
      .add(TweenMax.fromTo(sectionIntro, 1, {
        opacity: 1,
      }, {
        opacity: 0,
        ease: Power1.easeInOut,
      }))
      .add(TweenMax.to("header .logo", 1, {
        opacity: 1,
        ease: Power1.easeInOut,
      }),"together_01")
      .add(TweenMax.to(introVideoWrapper, 1, {
        autoAlpha: 0,
        ease: Power1.easeInOut,
      }),"together_01");
  
      new ScrollMagic.Scene({
        triggerElement: sectionIntro,
        // duration: window.innerHeight,
        duration: '90%',
        triggerHook: 0, // 0-top, 0.5-center, 1-bottom
      })
      .setTween(tweenIntro)
      // .addIndicators()
      .setPin(sectionIntro, {
        pushFollowers: false
      })
      // .setPin(sectionIntro)
      .addTo(controller);
    }
    // END Scene Section Intro
  
  
    // Scene Section Outer
    if (screen >= 768) {
      let sectionOuter = document.querySelectorAll(".section_outer");
  
      $.each(sectionOuter, function(index, value) {
        
        var getSectionIdIncludingHash = $(this).attr('id');
        var sectionIdWithoutHash = getSectionIdIncludingHash.replace(/^#/, '');
  
        // console.log(sectionIdWithoutHash);
  
        var isFirstElement = index === 0;
        var isLastElement = index == sectionOuter.length -1;
  
        let solutionsMainHeadline = $(this).find(".main_headline");
  
        var tweenSectionOuter = new TimelineMax();
  
        tweenSectionOuter.add(TweenMax.fromTo(solutionsMainHeadline, 1, {
          opacity: 0,
          top: 100 + "%",
        }, {
          opacity: 1,
          top: 50 + "%",
          color: "red",
          ease: Power1.easeInOut,
        }));
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "100%",
          triggerHook: 0,
        })
        // .setClassToggle(this, "active")
        .setTween(tweenSectionOuter)
        // .addIndicators()
        // .setPin(this)
        .addTo(controller);
      });
    }
    // END Scene Section Outer
    
    
    // Scene Section Solutions
    if (screen >= 768) {
      let sectionSolutions = document.querySelectorAll("section.solutions");
      let solutionsMainHeadline = document.querySelector(".main_headline #hl_solutions");
  
      $.each(sectionSolutions, function(index, value) {
      
        var isFirstElement = index === 0;
        var isLastElement = index == sectionSolutions.length -1;
  
        // let solutionsMainHeadline = $(this).find(".main_headline");
        let solutionsVideo = $(this).find(".video_bg");
        let solutionsVideoOverlay = $(this).find(".video_overlay");
        let solutionsEntryLayer = $(this).find(".entry_layer");
        let solutionsMainLayer = $(this).find(".main_layer");
        let solutionsEntryHeadline = $(this).find(".entry_headline");
        // let solutionsEntryContent = $(this).find(".entry_content");
        let solutionsSubline = $(this).find(".subline");
        let solutionsHeadline = $(this).find(".headline");
        let solutionsContent = $(this).find(".content");
        let solutionsLink = $(this).find(".section_link");
  
        var tweenSolutions = new TimelineMax();
  
        if (isFirstElement) {
          tweenSolutions.add(TweenMax.to("#nav_solutions", 0.1, {css:{className:'+=active'}}))
          .add(TweenMax.fromTo(introVideoOverlay, 0.1, {
            autoAlpha: 0,
          }, {
            autoAlpha: 0.8,
            ease: Power1.easeInOut,
          }),"together_01");
        }
  
        // tweenSolutions.add(TweenMax.fromTo(solutionsVideo, 0.5, {
        //   opacity: 0,
        // }, {
        //   opacity: 1,
        //   ease: Power1.easeInOut,
        // }));
        // tweenSolutions.add(TweenMax.to(solutionsVideoOverlay, 0, {
        //   opacity: 1,
        // }))
        // .add(TweenMax.to(solutionsVideo, 0.1, {
        //   opacity: 0.3,
        //   ease: Power1.easeInOut,
        // }))
        tweenSolutions.add(TweenMax.to(solutionsMainLayer, 0, {
          display: "flex",
        }),"together_01")
        .add(TweenMax.fromTo(solutionsSubline, 0.1, {
          autoAlpha: 0,
          // scale: 0.8,
        }, {
          autoAlpha: 1,
          // scale: 1,
          ease: Power1.easeInOut,
        }),"together_01")
        .add(TweenMax.fromTo(solutionsHeadline, 0.1, {
          autoAlpha: 0,
          // scale: 0.8,
        }, {
          autoAlpha: 1,
          // scale: 1,
          ease: Power1.easeInOut,
        }),"together_01")
        // .add(TweenMax.to(solutionsEntryContent, 0.1, {
        //   autoAlpha: 1,
          // scale: 0.8,
         //  ease: Power1.easeInOut,
        // }),"together_01")
        .add(TweenMax.to(solutionsContent, 0.05, {
          display: "flex",
        }))
        // .add(TweenMax.to(solutionsEntryContent, 0.1, {
         //  autoAlpha: 0,
          // scale: 0.8,
        //   ease: Power1.easeInOut,
        // }))
        .add(TweenMax.fromTo(solutionsContent, 0.05, {
          autoAlpha: 0,
          // scale: 0.8,
        }, {
          autoAlpha: 1,
          // scale: 1,
          ease: Power1.easeInOut,
        }),"same")
        .add(TweenMax.fromTo(solutionsLink, 0.1, {
          autoAlpha: 0,
          // scale: 0.8,
        }, {
          autoAlpha: 1,
          // scale: 1,
          ease: Power1.easeInOut,
        }),"same")
        // .add(TweenMax.to(solutionsVideo, 1, {
        //   autoAlpha: 1,
        //   ease: Power1.easeInOut,
        // }));
      
        if (isLastElement) {
          // tweenSolutions.add(TweenMax.to(solutionsVideo, 1, {
          //   autoAlpha: 0,
          //   ease: Power1.easeInOut,
          // }));
          tweenSolutions.add(TweenMax.to(introVideoWrapper, 0.1, { /* duration */
            autoAlpha: 0,
            ease: Power1.easeInOut,
          }));
          tweenSolutions.add(TweenMax.to("#nav_solutions", 0.1, {css:{className:'-=active'}}));
        }
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "100%", /* duration */
          triggerHook: 0,
        })
        .setTween(tweenSolutions)
        // .addIndicators()
        // .setPin(this)
        .setPin(this)
        .addTo(controller);
      });
  
      new ScrollMagic.Scene({
        triggerElement: "#solutions",
        duration: "500%",
        triggerHook: 0,
      })
      // .setClassToggle("#solutions", "active")
      .setPin("#solutions .section_headline_container", {
        pushFollowers: false
      })
      .addTo(controller);
    }
    // END Scene Section Solutions
  
  
     // Scene Section Projects
  if (screen >= 768) {
    let sectionProjects = document.querySelectorAll("section.projects");
    let projectsMainHeadline = document.querySelector(".main_headline #hl_projects");

    $.each(sectionProjects, function(index, value) {
      
      var isFirstElement = index === 0;
      var isLastElement = index == sectionProjects.length -1;

      let projectsTimeController = $(this).find(".section_con");

      let projectsEntryHeadline = $(this).find(".entry_headline");
      let projectsEntryContent = $(this).find(".entry_content");

      var tweenProjects = new TimelineMax();

      if (isFirstElement) {
        tweenProjects.add(TweenMax.to("#nav_projects", 0.1, {css:{className:'+=active'}}));
        // tweenProjects.add(TweenMax.to(".project_container", 0, {css:{className:'+=inview'}}));
        // tweenProjects.add(TweenMax.fromTo(projectsMainHeadline, 1, {
        //   opacity: 0,
        //   top: 100 + "%",
        // }, {
        //   opacity: 1,
        //   top: 50 + "%",
        //   ease: Power1.easeInOut,
        // }))
      }

      // tweenProjects.add(TweenMax.fromTo(projectsEntryHeadline, 1, {
      //   autoAlpha: 0,
      //   // scale: 1.2,
      //   x: "100%",
      // }, {
      //   autoAlpha: 1,
      //   // scale: 1,
      //   x: "0%",
      //   ease: "elastic",
      // }))
      // tweenProjects.add(TweenMax.fromTo(projectsEntryContent, 1, {
      //   autoAlpha: 0,
      //   // scale: 0.8,
      //   transformOrigin: "0% 100%",
      // }, {
      //   autoAlpha: 1,
      //   // scale: 1,
      //   ease: "elastic",
      // }));

      // var projectItems = $(this).find(".project_items_container > .project_item");
      // // console.log(elements1);

      // $.each(projectItems, function() {
      //   // console.log($(this).attr("id"));
      //   project_id = $(this).attr("id");
      //   projectItem = $(this);
      //   projectPreview = $("#"+project_id+"_preview");

      //   tweenProjects.add(TweenMax.to(".project_item, .project_preview", 0, {css:{className:'-=active'}}))
      //   // .add(TweenMax.to(".project_preview", 0, {css:{className:'-=active'}}))
      //   .add(TweenMax.to(projectItem.add(projectPreview), 1, {css:{className:'+=active'}}));
      //   // .add(TweenMax.staggerTo($("#"+project_id+"_preview"), 0, {css:{className:'+=active'}}));
      // });

      // var projectItemsCard = $(this).find(".project_items_cards_container > .project_item_card");
      
      // // Option1
      // function shuffleProjects(array) {
      //   let currentIndexProjects = array.length,  randomIndex;
      
      //   // While there remain elements to shuffle.
      //   while (currentIndexProjects != 0) {
      
      //     // Pick a remaining element.
      //     randomIndexProjects = Math.floor(Math.random() * currentIndexProjects);
      //     currentIndexProjects--;
      
      //     // And swap it with the current element.
      //     [array[currentIndexProjects], array[randomIndexProjects]] = [
      //       array[randomIndexProjects], array[currentIndexProjects]];
      //   }
      
      //   return array;
      // }
      
      // shuffleProjects(projectItemsCard);

      // $.each(projectItemsCard, function() {
      //   tweenProjects.to($(this), 0.2, {
      //     autoAlpha: 1,
      //     scale: 1,
      //     ease: Power1.easeInOut,
      //   });
      // });
      
      
      // tweenProjects.add(TweenMax.to(".project_item, .project_preview .project_image", 0, {css:{className:'-=active'}}));

      tweenProjects.add(TweenMax.fromTo(projectsTimeController, 1, {
        scale: 1,
      }, {
        scale: 1,
      }));

      if (isLastElement) {
        // tweenProjects.add(TweenMax.to(projectsMainHeadline, 1, {
        //   opacity: 0,
        //   top: 25 + "%",
        //   ease: Power1.easeInOut,
        // }));
        // tweenProjects.add(TweenMax.set(".project_container", 0, {css:{className:'-=inview'}}));
        tweenProjects.add(TweenMax.to("#nav_projects", 0.1, {css:{className:'-=active'}}));
      }

      new ScrollMagic.Scene({
        triggerElement: this,
        duration: "100%",
        triggerHook: 0,
        // toggleClass: "haribo",
      })
      .setTween(tweenProjects)
      // .addIndicators()
      .setPin(this)
      .addTo(controller);

      new ScrollMagic.Scene({
        triggerElement: this,
        duration: "100%",
        triggerHook: 0,
      })
      .setPin("#projects .section_headline_container")
      .addTo(controller);
    });
  }

  if (screen < 768) {
    initSliderOnMobile();
  }

  function initSliderOnMobile() {
    var projectSlider = $(".slider");

    $(projectSlider).each(function(index) {
      // console.log(projectSlider);

      var slideCount =  $(this).find("ul li").length;
      var slideWidth =  $(this).find("ul li").width();
      var slideHeight =  $(this).find("ul li").height();
      var slideUlWidth =  slideCount * slideWidth;
      var slideLiWidth =  100 / slideCount+"%";
      
      console.log(slideCount);

      $(this).css({"max-width":slideWidth, "height": slideHeight});
      $(this).find("ul").css({"width":slideUlWidth, "margin-left": - slideWidth });
      $(this).find("ul li").css({"width":slideLiWidth });
      $(this).find("ul li:last-child").prependTo($(this).find("ul"));
      
      function moveLeft(thisSlider) {
        thisSlider.find("ul").stop().animate({
          left: + slideWidth
        },700, function() {
          thisSlider.find("ul li:last-child").prependTo(thisSlider.find("ul"));
          thisSlider.find("ul").css("left","");
        });
      }
      
      function moveRight(thisSlider) {
        // console.log(nextButton);
        thisSlider.find("ul").stop().animate({
          left: - slideWidth
        },700, function() {
          thisSlider.find("ul li:first-child").appendTo(thisSlider.find("ul"));
          thisSlider.find("ul").css("left","");
        });
      }
      
      
      $(this).find(".next").on("click",function(){
        thisSlider = $(this).parent(".slider");
        // console.log(thisSlider);
        moveRight(thisSlider);
      });
      
      $(this).find(".prev").on("click",function(){
        thisSlider = $(this).parent(".slider");
        moveLeft(thisSlider);
      });
    
    });
  };
  // END Scene Section Projects
  
  
    // Scene Section USP
    if (screen >= 768) {
      let sectionUsp = document.querySelectorAll("section.usp");
      let uspMainHeadline = document.querySelector(".main_headline #hl_usp");
  
      $.each(sectionUsp, function(index, value) {
        
        var isFirstElement = index === 0;
        var isLastElement = index == sectionUsp.length -1;
  
        let uspEntryHeadline = $(this).find(".entry_headline");
        let uspEntryContent = $(this).find(".entry_content");
  
        var tweenUsp = new TimelineMax();
  
        if (isFirstElement) {
          tweenUsp.add(TweenMax.to("#nav_usp", 0.1, {css:{className:'+=active'}}));
        }
  
        tweenUsp.add(TweenMax.to(".section_cards", 1, {
          // x: "-120%",
          x: "-80%",
          // x: "-66.66%",
          ease: "elastic",
        }));
  
        if (isLastElement) {
          tweenUsp.add(TweenMax.to("#nav_usp", 0.1, {css:{className:'-=active'}}), "last");
        }
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "600%",
          triggerHook: 0,
        })
        .setTween(tweenUsp)
        // .addIndicators()
        .setPin(this)
        // .setPin(this, {
        //   pushFollowers: false
        // })
        .addTo(controller);
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "600%",
          triggerHook: 0,
        })
        .setPin("#usp .section_headline_container")
        .addTo(controller);
      });
    }  
    // END Scene Section USP
  
  
 // Scene Section Services
 if (screen >= 768) {
    
  let sectionServices = document.querySelectorAll("section.services");
  let sectionServicesVideoWrapper = document.querySelector(".section_video_wrapper");
  let sectionServicesVideo_01 = sectionServicesVideoWrapper.querySelector("#video_01");
  let sectionServicesVideo_02 = sectionServicesVideoWrapper.querySelector("#video_02");
  let sectionServicesVideo_03 = sectionServicesVideoWrapper.querySelector("#video_03");
  let servicesMainHeadline = document.querySelector(".main_headline #hl_services");

  $.each(sectionServices, function(index, value) {
    
    var isFirstElement = index === 0;
    var isSecondElement = index === 1;
    var isThirdElement = index === 2;
    var isLastElement = index == sectionServices.length -1;

    let servicesVideo = $(this).find(".video_bg");
    let servicesVideoOverlay = $(this).find(".video_overlay");

    let servicesTimeController = $(this).find(".section_con");

    let servicesEntryHeadline = $(this).find(".entry_headline");
    let servicesEntryContent = $(this).find(".entry_content");
    
    var tweenServices = new TimelineMax();

    if (isFirstElement) {
      tweenServices.add(TweenMax.to("#nav_services", 0.1, {css:{className:'+=active'}}));
      tweenServices.add(TweenMax.to(sectionServicesVideo_01, 0.1, {
        opacity: 1,
        ease: Power1.easeInOut,
      }));
      tweenServices.call(start_video_01);
    }

    if (isSecondElement) {
      tweenServices.add(TweenMax.to(sectionServicesVideo_01, 0.1, {
        opacity: 0,
        ease: Power1.easeInOut,
      }),"together_second_element");
      tweenServices.add(TweenMax.to(sectionServicesVideo_02, 0.1, {
        opacity: 1,
        ease: Power1.easeInOut,
      }),"together_second_element");
      tweenServices.call(start_video_02);
    }

    if (isThirdElement) {
      tweenServices.add(TweenMax.to(sectionServicesVideo_02, 0.1, {
        opacity: 0,
        ease: Power1.easeInOut,
      }),"together_third_element");
      tweenServices.add(TweenMax.to(sectionServicesVideo_03, 0.1, {
        opacity: 1,
        ease: Power1.easeInOut,
      }),"together_third_element");
      tweenServices.call(start_video_03);
    }

    function start_video_01() {
      sectionServicesVideo_01.play();
    }

    function start_video_02() {
      sectionServicesVideo_02.play();
    }

    function start_video_03() {
      sectionServicesVideo_03.play();
    }

    // tweenServices.add(TweenMax.fromTo(servicesVideo, 0.5, {
    //   opacity: 0,
    // }, {
    //   opacity: 1,
    //   ease: Power1.easeInOut,
    // }));
    // tweenServices.add(TweenMax.to(servicesVideoOverlay, 0, {
    //   opacity: 1,
    // }))
    // tweenServices.add(TweenMax.to(servicesVideo, 0.1, {
    //   opacity: 0.3,
    //   ease: Power1.easeInOut,
    // }));
    
    tweenServices.add(TweenMax.fromTo(servicesEntryHeadline, 1, {
      autoAlpha: 0,
      // scale: 1.2,
      x: "100%",
    }, {
      autoAlpha: 1,
      // scale: 1,
      x: "0%",
      ease: "elastic",
    }),"together_01")
    tweenServices.add(TweenMax.fromTo(servicesEntryContent, 1, {
      autoAlpha: 0,
      // scale: 0.8,
      transformOrigin: "0% 100%",
    }, {
      autoAlpha: 1,
      // scale: 1,
      ease: "elastic",
    }),"together_01");

    var linkElements = $(this).find("ul > li");
    // console.log(elements1);

    $.each(linkElements, function() {
      tweenServices.to($(this), 0.2, {
        autoAlpha: 1,
        // scale: 1,
        ease: "elastic",
      });
    });
       
    // tweenServices.add(TweenMax.to(servicesVideo, 1, {
    //   autoAlpha: 0,
    //   ease: Power1.easeInOut,
    // }));

    tweenServices.add(TweenMax.fromTo(servicesTimeController, 1, {
      scale: 1,
    }, {
      scale: 1,
    }));

    if (isLastElement) {
      // tweenServices.add(TweenMax.to(servicesMainHeadline, 1, {
      //   opacity: 0,
      //   top: 25 + "%",
      //   ease: Power1.easeInOut,
      // }));
      tweenServices.add(TweenMax.to("#nav_services", 0.1, {css:{className:'-=active'}}));
    }

    new ScrollMagic.Scene({
      triggerElement: this,
      duration: "200%",
      triggerHook: 0,
    })
    .setTween(tweenServices)
    // .addIndicators()
    .setPin(this)
    .addTo(controller);
  });

  new ScrollMagic.Scene({
    triggerElement: "#services",
    duration: "800%",
    triggerHook: 0,
  })
  .setPin("#services .section_headline_container", {
    pushFollowers: false
  })
  .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: sectionServicesVideoWrapper,
    duration: "800%",
    triggerHook: 0,
  })
  .setPin(sectionServicesVideoWrapper, {
    pushFollowers: false
  })
  .addTo(controller);
}
// END Scene Section Services
  
  
    // Scene Section Approach
    if (screen >= 768) {
      let sectionApproach = document.querySelectorAll("section.approach");
      let approachMainHeadline = document.querySelector(".main_headline #hl_approach");
  
      $.each(sectionApproach, function(index, value) {
  
        var isFirstElement = index === 0;
        var isLastElement = index == sectionApproach.length -1;
  
        let approachContainer = $(this).find(".approach_container");
        let approachOuterWrapper = $(this).find(".approach_outer_wrapper");
        let approachInnerWrapper = $(this).find(".approach_inner_wrapper");
        let approachStep1 = $(this).find("#step_01");
        let approachStep2 = $(this).find("#step_02");
        let approachStep3 = $(this).find("#step_03");
        let approachStep4 = $(this).find("#step_04");
        let approachStep5 = $(this).find("#step_05");
        let approachStep6 = $(this).find("#step_06");
  
        const tweenApproach = new TimelineMax();
  
        if (isFirstElement) {
          tweenApproach.add(TweenMax.to("#nav_approach", 0.1, {css:{className:'+=active'}}));
        }
      
        tweenApproach.add(
          TweenMax.fromTo(
            approachInnerWrapper,
            1,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              ease: Power1.easeInOut,
              // repeat: 1,
              // onCompleteParams: approachInnerWrapper,
              // onComplete: function () {
              //   approachInnerWrapper.classList.add("done");
              // },
            }
          ),
          "first"
        )
        .add(
          TweenMax.fromTo(
            approachOuterWrapper,
            1,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              ease: Power1.easeInOut,
            }
          )
        )
        .add(
          TweenMax.fromTo(
            approachStep1,
            1,
            {
              opacity: 0,
              // scale: 1.2,
            },
            {
              opacity: 1,
              // scale: 1,
              ease: "cubic-bezier(.7,.16,.2,.9)",
            }
          )
        )
        .add(
          TweenMax.fromTo(
            approachStep2,
            1,
            {
              opacity: 0,
              // scale: 1.2,
            },
            {
              opacity: 1,
              // scale: 1,
              ease: "cubic-bezier(.7,.16,.2,.9)",
            }
          )
        )
        .add(
          TweenMax.fromTo(
            approachStep3,
            1,
            {
              opacity: 0,
              // scale: 1.2,
            },
            {
              opacity: 1,
              // scale: 1,
              ease: "cubic-bezier(.7,.16,.2,.9)",
            }
          )
        )
        .add(
          TweenMax.fromTo(
            approachStep4,
            1,
            {
              opacity: 0,
              // scale: 1.2,
            },
            {
              opacity: 1,
              // scale: 1,
              ease: "cubic-bezier(.7,.16,.2,.9)",
            }
          )
        )
        .add(
          TweenMax.fromTo(
            approachStep5,
            1,
            {
              opacity: 0,
              // scale: 1.2,
            },
            {
              opacity: 1,
              // scale: 1,
              ease: "cubic-bezier(.7,.16,.2,.9)",
            }
          )
        )
        .add(
          TweenMax.fromTo(
            approachStep6,
            1,
            {
              opacity: 0,
              // scale: 1.2,
            },
            {
              opacity: 1,
              // scale: 1,
              ease: "cubic-bezier(.7,.16,.2,.9)",
            }
          )
        );
  
        if (isLastElement) {
          tweenApproach.add(TweenMax.to("#nav_approach", 0.1, {css:{className:'-=active'}}));
        }
    
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: 3000,
          // duration: window.innerHeight*5,
          triggerHook: 0, // 0-top, 0.5-center, 1-bottom
        })
        // .setClassToggle(".navigation #approach", "active")
        .setTween(tweenApproach)
        // .addIndicators()
        .setPin(this)
        .addTo(controller);
    
        new ScrollMagic.Scene({
          triggerElement: "#approach",
          duration: 3000,
          triggerHook: 0,
        })
        .setPin("#approach .section_headline_container")
        .addTo(controller);
      });
    }
    // End Scene Section Approach
  
  
    // Scene Section Thatsus
    if (screen >= 768) {
      let sectionThatsus = document.querySelectorAll("section.thatsus");
      let thatsusMainHeadline = document.querySelector(".main_headline #hl_thatsus");
  
      $.each(sectionThatsus, function(index, value) {
        
        var isFirstElement = index === 0;
        var isLastElement = index == sectionThatsus.length -1;
  
        let thatsusTimeController = $(this).find(".section_con");
  
        let thatsusMember = $(this).find(".team");
        let thatsusWannaJoin = $(this).find(".join");
        // let thatsusTimer = $(this).find(".timer");
        
        var tweenThatsus = new TimelineMax();
  
        if (isFirstElement) {
          tweenThatsus.add(TweenMax.to("#nav_thatsus", 0.1, {css:{className:'+=active'}}));
          // tweenThatsus.add(TweenMax.fromTo(thatsusMainHeadline, 1, {
          //   opacity: 0,
          //   top: 100 + "%",
          // }, {
          //   opacity: 1,
          //   top: 50 + "%",
          //   ease: Power1.easeInOut,
          // }))
        }
  
        // Option1
        function shuffle(array) {
          let currentIndex = array.length,  randomIndex;
        
          // While there remain elements to shuffle.
          while (currentIndex != 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
              array[randomIndex], array[currentIndex]];
          }
        
          return array;
        }
        
        shuffle(thatsusMember);
        // console.log(thatsusMember);
  
        $.each(thatsusMember, function() {
          tweenThatsus.to($(this), 0.2, {
            autoAlpha: 1,
            scale: 1,
            ease: Power1.easeInOut,
          });
        });
        // Option1
  
        // Option2
        // tweenThatsus.add(TweenMax.fromTo(thatsusTimeController, 1, {
        //   scale: 1,
        // }, {
        //   scale: 1,
        //   onComplete: thatsusMemberInterval,
        // }));
  
        // function thatsusMemberInterval(){
        //   var interval = setInterval(function () {
        //     var $ds = thatsusMember.not(".fade-in");
        //     $ds.eq(Math.floor(Math.random() * $ds.length)).addClass('fade-in');
        //     if ($ds.length == 1) {
        //         clearInterval(interval);
        //     }
        //   }, 300);
        // }
        // Option2
        
        tweenThatsus.add(TweenMax.to(thatsusWannaJoin, 0.5, {
          autoAlpha: 1,
          scale: 1,
          ease: Power1.easeInOut,
        }));
  
        tweenThatsus.add(TweenMax.fromTo(thatsusTimeController, 1, {
          scale: 1,
        }, {
          scale: 1,
        }));
  
        if (isLastElement) {
          // tweenThatsus.add(TweenMax.to(thatsusMainHeadline, 1, {
          //   opacity: 0,
          //   top: 25 + "%",
          //   ease: Power1.easeInOut,
          // }));
          tweenThatsus.add(TweenMax.to("#nav_thatsus", 0.1, {css:{className:'-=active'}}));
        }
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "300%",
          triggerHook: 0,
        })
        .setTween(tweenThatsus)
        // .addIndicators()
        .setPin(this)
        .addTo(controller);
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "300%",
          triggerHook: 0,
        })
        .setPin("#thatsus .section_headline_container")
        .addTo(controller);
      });
    }
    // END Scene Section Thatsus

    // Scene Section Partners
    if (screen >= 768) {
      let sectionPartners = document.querySelectorAll("section.partners");
      let partnersMainHeadline = document.querySelector(".main_headline #hl_partners");
  
      $.each(sectionPartners, function(index, value) {
        
        var isFirstElement = index === 0;
        var isLastElement = index == sectionPartners.length -1;
  
        let PartnersTimeController = $(this).find(".section_con");
  
        let PartnersLogo = $(this).find(".logo_item_partners");
        let PartnersTimer = $(this).find(".timer");
        
        var tweenPartners = new TimelineMax();
  
        if (isFirstElement) {
          tweenPartners.add(TweenMax.to("#nav_partners", 0.1, {css:{className:'+=active'}}));
          // tweenPart.add(TweenMax.fromTo(partMainHeadline, 1, {
          //   opacity: 0,
          //   top: 100 + "%",
          // }, {
          //   opacity: 1,
          //   top: 50 + "%",
          //   ease: Power1.easeInOut,
          // }))
        }
  
        $.each(PartnersLogo, function() {
          tweenPartners.to($(this), 0.2, {
            autoAlpha: 1,
            scale: 1,
            ease: Power1.easeInOut,
          });
        });
          
        // tweenPart.add(TweenMax.to(partTimer, 3, {
        //   autoAlpha: 1,
        //   ease: Power1.easeInOut,
        // }));
  
        tweenPartners.add(TweenMax.fromTo(PartnersTimeController, 1, {
          scale: 1,
        }, {
          scale: 1,
        }));
  
        if (isLastElement) {
          // tweenPart.add(TweenMax.to(partMainHeadline, 1, {
          //   opacity: 0,
          //   top: 25 + "%",
          //   ease: Power1.easeInOut,
          // }));
          tweenPartners.add(TweenMax.to("#nav_partners", 0.1, {css:{className:'-=active'}}));
        }
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "200%",
          triggerHook: 0,
        })
        .setTween(tweenPartners)
        // .addIndicators()
        .setPin(this)
        .addTo(controller);
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "200%",
          triggerHook: 0,
        })
        .setPin("#partners .section_headline_container")
        .addTo(controller);
      });
    }
    // END Scene Section Partners
  
  
    // Scene Section Part
    if (screen >= 768) {
      let sectionPart = document.querySelectorAll("section.part");
      let partMainHeadline = document.querySelector(".main_headline #hl_part");
  
      $.each(sectionPart, function(index, value) {
        
        var isFirstElement = index === 0;
        var isLastElement = index == sectionPart.length -1;
  
        let partTimeController = $(this).find(".section_con");
  
        let partLogo = $(this).find(".logo_item");
        let partTimer = $(this).find(".timer");
        
        var tweenPart = new TimelineMax();
  
        if (isFirstElement) {
          tweenPart.add(TweenMax.to("#nav_part", 0.1, {css:{className:'+=active'}}));
          // tweenPart.add(TweenMax.fromTo(partMainHeadline, 1, {
          //   opacity: 0,
          //   top: 100 + "%",
          // }, {
          //   opacity: 1,
          //   top: 50 + "%",
          //   ease: Power1.easeInOut,
          // }))
        }
  
        $.each(partLogo, function() {
          tweenPart.to($(this), 0.2, {
            autoAlpha: 1,
            scale: 1,
            ease: Power1.easeInOut,
          });
        });
          
        // tweenPart.add(TweenMax.to(partTimer, 3, {
        //   autoAlpha: 1,
        //   ease: Power1.easeInOut,
        // }));
  
        tweenPart.add(TweenMax.fromTo(partTimeController, 1, {
          scale: 1,
        }, {
          scale: 1,
        }));
  
        if (isLastElement) {
          // tweenPart.add(TweenMax.to(partMainHeadline, 1, {
          //   opacity: 0,
          //   top: 25 + "%",
          //   ease: Power1.easeInOut,
          // }));
          tweenPart.add(TweenMax.to("#nav_part", 0.1, {css:{className:'-=active'}}));
        }
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "200%",
          triggerHook: 0,
        })
        .setTween(tweenPart)
        // .addIndicators()
        .setPin(this)
        .addTo(controller);
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "200%",
          triggerHook: 0,
        })
        .setPin("#part .section_headline_container")
        .addTo(controller);
      });
    }
    // END Scene Section Part
  
    // Scene Section Contact
    if (screen >= 768) {
      let sectionContact = document.querySelectorAll("section.contact");
      let contactMainHeadline = document.querySelector(".main_headline #hl_contact");
  
      $.each(sectionContact, function(index, value) {
        
        var isFirstElement = index === 0;
        var isLastElement = index == sectionContact.length -1;
  
        var tweenContact = new TimelineMax();
  
        if (isFirstElement) {
          tweenContact.add(TweenMax.to("#nav_contact", 0.1, {css:{className:'+=active'}}));
          // tweenContact.add(TweenMax.fromTo(contactMainHeadline, 1, {
          //   opacity: 0,
          //   top: 100 + "%",
          // }, {
          //   opacity: 1,
          //   top: 50 + "%",
          //   ease: Power1.easeInOut,
          // }))
        }
  
        if (isLastElement) {
          // tweenContact.add(TweenMax.to(contactMainHeadline, 1, {
          //   opacity: 0,
          //   top: 25 + "%",
          //   ease: Power1.easeInOut,
          // }));
          tweenContact.add(TweenMax.to("#nav_contact", 0.1, {css:{className:'-=active'}}));
        }
  
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: "100%",
          triggerHook: 0.5,
        })
        .setTween(tweenContact)
        // .addIndicators()
        // .setPin(this)
        .addTo(controller);
      });
    }
    // END Scene Section Contact
  };
  
  ScreenSetup();
  // window.onresize = ScreenSetup;
  // window.onresize = function(){ location.reload(); }
  $(window).resize(function(){
    if (window.innerWidth >= 768) {
      location.reload();
    }
  });
  // $(window).on('resize',function(){location.reload();});
  
  
  // JQUERY FUNCTIONS
  $(document).ready(function () {
  
    // Add Loading when ready
    setTimeout(function () {
      $("body").addClass("loading");
    }, 300);
  
    // Add Loaded for intro animation
    setTimeout(function () {
      $("body").addClass("loaded").removeClass("loading");
    }, 2500);
    
    // logo
    $('.logo').click(function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $("#intro").offset().top - window.innerHeight
      }, 300);
    });
  
    // hamburger
    $('.hamburger').click(function (e) {
      e.preventDefault();
      $('header').toggleClass('active');
    });
  
    // Header Navigation
    if (window.innerWidth >= 768) {
  
      $(".navigation a").click(function (e) {
        //e.preventDefault();
  
        $("body").removeClass("project_view_active");
  
        var navSectionID = $(this).attr('href');
  
        var offsetPlus = "";
  
        //console.log(window.innerHeight);
        // 1183
  
        if (navSectionID == "#langswitch") {
          // link to href
          window.location.href = $(this).attr('href');
          // check span inside a tag
          let actualLang = $(this).find('span').text();
          if (actualLang == "DE") {
          window.location.href = '/de';
          } else {
          window.location.href = '/en';
          }
          return;
        }
  
        if (navSectionID == "#solutions") {
          // offsetPlus = 4300;
          offsetPlus = window.innerHeight*3.6;
        }
  
        if (navSectionID == "#projects") {
          // offsetPlus = 5020;
          offsetPlus = window.innerHeight*1.0;
        }
  
        if (navSectionID == "#usp") {
          // offsetPlus = 5500;
          offsetPlus = window.innerHeight*4.65;
        }
  
        if (navSectionID == "#services") {
          // offsetPlus = 6800;
          // offsetPlus = window.innerHeight*5.75;
          offsetPlus = window.innerHeight*1.5;
        }
        
        if (navSectionID == "#approach") {
          // offsetPlus = 700;
          offsetPlus = window.innerHeight*0.6;
        }
  
        if (navSectionID == "#thatsus") {
          // offsetPlus = 2700;
          offsetPlus = window.innerHeight*2.3;
        }

        if (navSectionID == "#partners") {
          // offsetPlus = 2700;
          offsetPlus = window.innerHeight*1.4;
        }
  
        $('html, body').animate({
          scrollTop: $(navSectionID).offset().top + offsetPlus
        }, 300);
      });
    }
  
    if (window.innerWidth < 768) {
  
      $(".navigation a").click(function (e) {
        //e.preventDefault();
  
        $("body").removeClass("project_view_active");
        $('header').removeClass('active');
  
        var navSectionID = $(this).attr('href');
  
        $('html, body').animate({
          scrollTop: $(navSectionID).offset().top - 120
        }, 300);
      });
    }
  
    // Scroll Indicator
    if (window.innerWidth >= 768) {
      $(".intro_scroller button").click(function (e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $("#solutions").offset().top + window.innerHeight*3.6
        }, 300);
      });
    }
  
    if (window.innerWidth < 768) {
      $(".intro_scroller button").click(function (e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $("#solutions").offset().top - 120
        }, 300);
      });
    }
  
    // Accordion
    // var allPanels = $(".accordion > dd").hide();
  
    // $(".accordion > dt > a").click(function () {
    //   allPanels.slideUp();
    //   $(this).parent().next().slideDown();
    //   return false;
    // });
  
    // Project Hover
    $(".project_item").hover(function () {
      popupID = $(this).attr("id");
      projectPreview = $(".project_preview_container").find("#"+popupID+"_preview");
  
      $(".project_container").toggleClass("project_hover");
      $(this).toggleClass("project_hover");
      projectPreview.toggleClass("project_hover");
    });
  
    // Project Popup
    //$(function() {
    //  $(".project_item_card").click(function (e) {
    //    e.preventDefault();
        // alert('click');
    //    popupID = $(this).attr("id");
    //    projectPopup = $(".popup_con").find("#"+popupID+"_popup");
  
    //    $("body").addClass("project_view_active");
    //    $(projectPopup).addClass("active");
    //    initSlider();
        // alert('init');
        // $(".popup_con").addClass("active");
    //  });
  
    // $(".popup button.close").click(function (e) {
    //    e.preventDefault();
  
    //    popupID = $(this).attr("id");
    //    projectPopup = $(".popup_con").find("#"+popupID+"_popup");
  
      //  $("body").removeClass("project_view_active");
      //  $(".popup").removeClass("active");
        // $(".popup_con").removeClass("active");
      //});
    //});
  
    // Project Slider
    function initSlider() {
      var projectSlider = $(".slider");
  
      $(projectSlider).each(function(index) {
        // console.log(projectSlider);
  
        var slideCount =  $(this).find("ul li").length;
        var slideWidth =  $(this).find("ul li").width();
        var slideHeight =  $(this).find("ul li").height();
        var slideUlWidth =  slideCount * slideWidth;
        var slideLiWidth =  100 / slideCount+"%";
        
        console.log(slideCount);
  
        $(this).css({"max-width":slideWidth, "height": slideHeight});
        $(this).find("ul").css({"width":slideUlWidth, "margin-left": - slideWidth });
        $(this).find("ul li").css({"width":slideLiWidth });
        $(this).find("ul li:last-child").prependTo($(this).find("ul"));
        
        function moveLeft(thisSlider) {
          thisSlider.find("ul").stop().animate({
            left: + slideWidth
          },700, function() {
            thisSlider.find("ul li:last-child").prependTo(thisSlider.find("ul"));
            thisSlider.find("ul").css("left","");
          });
        }
        
        function moveRight(thisSlider) {
          // console.log(nextButton);
          thisSlider.find("ul").stop().animate({
            left: - slideWidth
          },700, function() {
            thisSlider.find("ul li:first-child").appendTo(thisSlider.find("ul"));
            thisSlider.find("ul").css("left","");
          });
        }
        
        
        $(this).find(".next").on("click",function(){
          thisSlider = $(this).parent(".slider");
          // console.log(thisSlider);
          moveRight(thisSlider);
        });
        
        $(this).find(".prev").on("click",function(){
          thisSlider = $(this).parent(".slider");
          moveLeft(thisSlider);
        });
      
      });
    };
  });
  
  // JQUERY SCROLL FUNCTIONS
  $(window).scroll(function () {
    topOffset = $(this).scrollTop();
    $("#scroll_debugger").html(topOffset);
    // console.log(topOffset);
  
    // var theta = $(window).scrollTop() / 200;
    var theta = $(window).scrollTop() / 12000;
    // console.log(theta);
  
    $("#approach .approach_outer_wrapper > img").css({
      transform: "rotate(" + theta + "rad)",
    });
  });
  
  /* ===============================  Scroll back to top  =============================== */
  
  $(document).ready(function () {
    "use strict";
  
    var progressPath = document.querySelector('.progress-wrap path');
    var pathLength = progressPath.getTotalLength();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
    progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
    var updateProgress = function () {
        var scroll = $(window).scrollTop();
        var height = $(document).height() - $(window).height();
        var progress = pathLength - (scroll * pathLength / height);
        progressPath.style.strokeDashoffset = progress;
    }
    updateProgress();
    $(window).scroll(updateProgress);
    var offset = $(window).height();
    var duration = 550;
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > offset) {
            $('.progress-wrap').addClass('active-progress');
            $('.fixed_footer_container').addClass('active-progress');
        } else {
            $('.progress-wrap').removeClass('active-progress');
            $('.fixed_footer_container').removeClass('active-progress');
        }
    });
    $('.progress-wrap').on('click', function (event) {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, duration);
        return false;
    })
  });
  
  /* ===============================  END Scroll back to top  =============================== */
  
  
  /* ===============================  END Scroll back to top  =============================== */

  /* ===============================  Code aus der theme.js / Layout  =============================== */
  
  console.clear();

gsap.registerPlugin(ScrollTrigger);

// Get all sections that have an ID defined
const sections = document.querySelectorAll("section[id]");

// Add an event listener listening for scroll
window.addEventListener("scroll", navHighlighter);

function navHighlighter() {
  
  // Get current scroll position
  let scrollY = window.pageYOffset;
  
  // Now we loop through sections to get height, top and ID values for each
  sections.forEach(current => {
    const sectionHeight = current.offsetHeight;
    // const sectionTop = current.offsetTop;
    const sectionTop = current.offsetTop - 40;
    sectionId = current.getAttribute("id");
    
    /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */
    if (
      scrollY > sectionTop &&
      scrollY <= sectionTop + sectionHeight
    ){
      document.querySelector(".navigation a[href*=" + sectionId + "]").parentElement.classList.add("active");
      document.querySelector("section#" + sectionId).classList.add("active");
    } else {
      document.querySelector(".navigation a[href*=" + sectionId + "]").parentElement.classList.remove("active");
      document.querySelector("section#" + sectionId).classList.remove("active");
    }
  });
}


$(document).ready(function () {
  // Add Loading when ready
  setTimeout(function () {
    $("body").addClass("loading");
  }, 300);

  // Add Loaded for intro animation
  setTimeout(function () {
    $("body").addClass("loaded").removeClass("loading");
  }, 2500);
  
  // Hamburger Menu
  $(".hamburger").click(function (e) {
    e.preventDefault();
    $("header").toggleClass("active");
  });

  // Scroll Indicator
  $(".intro_scroller button").click(function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $("#solutions").offset().top + 800
  }, 1000);
  });
});


// START SCROLL ANIMATIONS
// $(function() {
  
//   // Function which adds the 'animated' class to any '.animatable' in view
//   var doAnimations = function() {
    
//     // Calc current offset and get all animatables
//     var offset = $(window).scrollTop() + $(window).height(),
//         $animatables = $('.animatable');
    
//     // Unbind scroll handler if we have no animatables
//     if ($animatables.length == 0) {
//       $(window).off('scroll', doAnimations);
//     }
    
//     // Check all animatables and animate them if necessary
// 		$animatables.each(function(i) {
//        var $animatable = $(this);
// 			if (($animatable.offset().top + $animatable.height() - 20) < offset) {
//         $animatable.removeClass('animatable').addClass('animated');
// 			}
//     });

// 	};
  
//   // Hook doAnimations on scroll, and trigger a scroll
// 	$(window).on('scroll', doAnimations);
//   $(window).trigger('scroll');
// });

const sectionIntroHeight = $("section#intro").height();
const sectionSolutionsHeight = $("section#solutions").height();
const sectionProjectsHeight = $("section#projects").height();
const sectionUspHeight = $("section#usp").height();
const sectionServicesHeight = $("section#services").height();
const sectionApproachHeight = $("section#approach").height();
const sectionThatsusHeight = $("section#thatsus").height();
const sectionPartHeight = $("section#part").height();
const sectionContactHeight = $("section#contact").height();

console.log(sectionIntroHeight);

$(window).scroll(function() {
  topOffset = $(this).scrollTop();
  $("#scroll_debugger").html(topOffset);

  // SCROLL ANIMATIONS SECTION INTRO
  if ($("section#intro").hasClass("active")) {

    if (topOffset >= 600) { 
      $("section#intro .intro_scroller").css({
        'opacity': '0',
        'transition': 'opacity 0.3s ease-in-out'
      });
    } else {
      $("section#intro .intro_scroller").css({
        'opacity': '1',
        'transition': 'opacity 0.3s ease-in-out'
      });
    }

    if (topOffset >= 600) { 
      $("section#intro .intro_img, section#intro .intro_content").css({
        'opacity': '0',
        'transition': 'opacity 0.1s ease-in-out'
      });
    }

    if (topOffset >= 500 && topOffset < 600) { 
      $("section#intro .intro_img, section#intro .intro_content").css({
        'opacity': '0.2',
        'transition': 'opacity 0.1s ease-in-out'
      });
    }

    if (topOffset >= 400 && topOffset < 500) { 
      $("section#intro .intro_img, section#intro .intro_content").css({
        'opacity': '0.4',
        'transition': 'opacity 0.1s ease-in-out'
      });
    }

    if (topOffset >= 300 && topOffset < 400) { 
      $("section#intro .intro_img, section#intro .intro_content").css({
        'opacity': '0.6',
        'transition': 'opacity 0.1s ease-in-out'
      });
    }

    if (topOffset >= 200 && topOffset < 300) { 
      $("section#intro .intro_img, section#intro .intro_content").css({
        'opacity': '0.8',
        'transition': 'opacity 1s ease-in-out'
      });
    }

    if (topOffset < 200) { 
      // $("section#intro .intro_img").fadeIn("slow");
      // $("section#intro .intro_content").fadeIn("slow");
      $("section#intro .intro_img, section#intro .intro_content").css({
        'opacity': '1',
        'transition': 'opacity 1s ease-in-out'
      });
    }

  }


  // SCROLL ANIMATIONS SECTION SOLUTIONS
  if ($("section#solutions").hasClass("active")) {
    
    if (topOffset >= sectionIntroHeight + 500) { 
      $("section#solutions .video_bg").css({
        opacity: 1
      });
      $("section#solutions .headline, section#solutions .content").css({
        opacity: 1
      });
    } else if (topOffset >= sectionIntroHeight + 400 && topOffset < sectionIntroHeight + 500) { 
      $("section#solutions .video_bg").css({
        opacity: 0.8
      });
      $("section#solutions .headline, section#solutions .content").css({
        opacity: 0.8
      });
    } else if (topOffset >= sectionIntroHeight + 300 && topOffset < sectionIntroHeight + 400) { 
      $("section#solutions .video_bg").css({
        opacity: 0.6
      });
      $("section#solutions .headline, section#solutions .content").css({
        opacity: 0.6
      });
    } else if (topOffset >= sectionIntroHeight + 200 && topOffset < sectionIntroHeight + 300) { 
      $("section#solutions .video_bg").css({
        opacity: 0.4
      });
      $("section#solutions .headline, section#solutions .content").css({
        opacity: 0.4
      });
    } else if (topOffset >= sectionIntroHeight + 100 && topOffset < sectionIntroHeight + 200) { 
      $("section#solutions .video_bg").css({
        opacity: 0.2
      });
      $("section#solutions .headline, section#solutions .content").css({
        opacity: 0.2
      });
    } else if (topOffset < sectionIntroHeight + 100) { 
      $("section#solutions .video_bg").css({
        opacity: 0
      });
      $("section#solutions .headline, section#solutions .content").css({
        opacity: 0
      });
    }
  
  }

  // SCROLL ANIMATIONS SECTION APPROACH
  if ($("section#approach").hasClass("active")) {

    targetApproach = sectionIntroHeight + sectionSolutionsHeight + sectionProjectsHeight + sectionUspHeight + sectionServicesHeight;

    // if (topOffset >= targetApproach + 500) { 
    //   $("section#solutions .video_bg").css({
    //     opacity: 1
    //   });
    //   $("section#solutions .headline, section#solutions .content").css({
    //     opacity: 1
    //   });
    // } else if (topOffset >= targetApproach + 400 && topOffset < targetApproach + 500) { 
    //   $("section#solutions .video_bg").css({
    //     opacity: 0.8
    //   });
    //   $("section#solutions .headline, section#solutions .content").css({
    //     opacity: 0.8
    //   });
    // } else if (topOffset >= targetApproach + 300 && topOffset < targetApproach + 400) { 
    //   $("section#solutions .video_bg").css({
    //     opacity: 0.6
    //   });
    //   $("section#solutions .headline, section#solutions .content").css({
    //     opacity: 0.6
    //   });
    // } else if (topOffset >= targetApproach + 200 && topOffset < targetApproach + 300) { 
    //   $("section#solutions .video_bg").css({
    //     opacity: 0.4
    //   });
    //   $("section#solutions .headline, section#solutions .content").css({
    //     opacity: 0.4
    //   });
    // } else if (topOffset >= targetApproach + 100 && topOffset < targetApproach + 200) { 
    //   $("section#solutions .video_bg").css({
    //     opacity: 0.2
    //   });
    //   $("section#solutions .headline, section#solutions .content").css({
    //     opacity: 0.2
    //   });
    // } else if (topOffset < targetApproach + 100) { 
    //   $("section#solutions .video_bg").css({
    //     opacity: 0
    //   });
    //   $("section#solutions .headline, section#solutions .content").css({
    //     opacity: 0
    //   });
    // }

    if (topOffset > targetApproach + 100) {
      $("section#approach .approach_inner_wrapper").addClass("active");
      // $("section#approach .approach_inner_wrapper img").css({
      //   'visibility': 'visible',
      //   'opacity': '1',
      //   'transform': 'matrix(1, 0, 0, 1, 0, 0)'
      // });

      // console.log('target');
      
      // TweenMax.to(
      //   $("section#approach .approach_inner_wrapper img"),
      //   0.5,
      //   // { scale: 1, opacity: 1, startAt: { visibility: "visible" } },
      //   {
      //     delay: 0.15,
      //     scale: 0,
      //     ease: "Back.easeIn",
      //     opacity: 0,
      //     onCompleteParams: [$("section#approach .approach_inner_wrapper img")],
      //     onComplete: function () {
      //       $("section#approach .approach_inner_wrapper img").css({ visibility: "hidden", opacity: 1, scale: 3 });
      //     },
      //   }
      // ); 

      // TweenMax.to(
      //   $("section#approach .approach_inner_wrapper img"),
      //   0.5,
      //   // { scale: 3, opacity: 1, rotationX: 0, visibility: "visible" },
      //   { 
      //     delay: 0.15, 
      //     // scale: 1,
      //     matrix: "(1, 0, 1, 0, 0, 0)", 
      //     opacity: 1, 
      //     ease: "Back.easeOut",
      //     // onCompleteParams: [$("section#approach .approach_inner_wrapper img")],
      //     // onComplete: function () {
      //     //   $("section#approach .approach_inner_wrapper img").css({ visibility: "visible", opacity: 1, scale: 3 });
      //     // },
      //   }
      // );

      // setTimeout(function () {
      //   TweenMax.to(
      //     $("section#approach .approach_inner_wrapper img"),
      //     0.5,
      //     // { scale: 3, opacity: 0, rotationX: 0, visibility: "visible" },
      //     { delay: 0.15, scale: 1, opacity: 1, ease: "Back.easeOut" }
      //   );
      // }, 400);

      

    } else {
      $("section#approach .approach_inner_wrapper").removeClass("active");
      // $("section#approach .approach_inner_wrapper img").css({
      //   'visibility': 'visible',
      //   'opacity': '0',
      //   'transform': 'matrix(0, 0, 0, 0, 0, 0)'
      // });

      // $("section#approach .approach_inner_wrapper img").css({
      //   'visibility': 'visible',
      //   'opacity': '0',
      //   'transform': 'matrix(0, 0, 0, 0, 0, 0)'
      // });

      // TweenMax.to(
      //   $("section#approach .approach_inner_wrapper img"),
      //   0.5,
      //   // { scale: 1, opacity: 1 },
      //   {
      //     delay: 0.15,
      //     scale: 0,
      //     ease: "Back.easeIn",
      //     opacity: 0,
      //     onCompleteParams: [$("section#approach .approach_inner_wrapper img")],
      //     onComplete: function () {
      //       $("section#approach .approach_inner_wrapper img").css({ visibility: "hidden", opacity: 1, scale: 3 });
      //     },
      //   }
      // );

      // TweenMax.fromTo(
      //   $("section#approach .approach_inner_wrapper img"),
      //   0.5,
      //   { scale: 3, opacity: 0, rotationX: 0, visibility: "visible" },
      //   { 
      //     delay: 0.15, 
      //     scale: 1, 
      //     opacity: 1, 
      //     ease: "Back.easeOut",
      //     onCompleteParams: [$("section#approach .approach_inner_wrapper img")],
      //     onComplete: function () {
      //       $("section#approach .approach_inner_wrapper img").css({ visibility: "hidden", opacity: 1, scale: 3 });
      //     },
      //   }
      // );
    }

    if (topOffset > targetApproach + 200) {
      var theta = $(window).scrollTop() / 50;
      // var theta = $(window).scrollTop() / 10 % Math.PI;
      // Math.min(0, theta)
      $("section#approach .approach_outer_wrapper").addClass("active");
      $('section#approach .approach_outer_wrapper img').css({ transform: 'rotate(' + theta + 'rad)' });
    } else {
      $("section#approach .approach_outer_wrapper").removeClass("active");
    }

    if (topOffset > targetApproach + 300) {
      $("section#approach .approach_step_wrapper #step_01").addClass("active");
      
    } else {
    
    }

    if (topOffset > targetApproach + 400) {
      $("section#approach .approach_step_wrapper #step_02").addClass("active");
      $("section#approach .approach_step_wrapper").addClass("step_02");
      
    } else {
    
    }

    if (topOffset > targetApproach + 500) {
      $("section#approach .approach_step_wrapper #step_03").addClass("active");
      $("section#approach .approach_step_wrapper").addClass("step_03");
      
    } else {
    
    }

    if (topOffset > targetApproach + 600) {
      $("section#approach .approach_step_wrapper #step_04").addClass("active");
      $("section#approach .approach_step_wrapper").addClass("step_04");
      
    } else {
    
    }

    if (topOffset > targetApproach + 700) {
      $("section#approach .approach_step_wrapper #step_05").addClass("active");
      $("section#approach .approach_step_wrapper").addClass("step_05");
      
    } else {
    
    }

    if (topOffset > targetApproach + 800) {
      $("section#approach .approach_step_wrapper #step_06").addClass("active");
      $("section#approach .approach_step_wrapper").addClass("step_06");
      
    } else {
    
    }

    if (topOffset > targetApproach + 1000) {
      $("section#approach .section_con").addClass("move");
    } else {
      $("section#approach .section_con").removeClass("move");   
    }

  }

  // SCROLL ANIMATIONS SECTION THATSUS
  // const members = gsap.utils.toArray('.member');

  //   members.forEach((member, i) => {
  //     // const anim = gsap.to(member, {autoAlpha: 0, y: 50}, {duration: 1, autoAlpha: 1, y: 0});
  //     ScrollTrigger.create({
  //       trigger: member,
  //       start: "center center",
  //       end: "center center",
  //       toggleClass: "active",
  //       // animation: anim,
  //       // toggleActions: 'play none none none',
  //       // once: true,
  //       markers: true,
  //     });
  //   });

  if ($("section#thatsus").hasClass("active")) {

    targetThatsus = sectionIntroHeight + sectionSolutionsHeight + sectionProjectsHeight + sectionUspHeight + sectionServicesHeight + sectionApproachHeight;

    // const members = gsap.utils.toArray('.member');

    // members.forEach((member, i) => {
    //   // const anim = gsap.to(member, {autoAlpha: 0, y: 50}, {duration: 1, autoAlpha: 1, y: 0});
    //   ScrollTrigger.create({
    //     trigger: member,
    //     start: "center center",
    //     end: "center center",
    //     toggleClass: "active",
    //     // animation: anim,
    //     // toggleActions: 'play none none none',
    //     // once: true,
    //     markers: true,
    //   });
    // });

    // let tl = gsap.timeline({scrollTrigger:{
    //   trigger:".team_members",
    //   start:"top 50%",
    //   end:"bottom top",
    //   // toggleActions:"restart none none reset"
    // }})

    // if (topOffset > targetThatsus + 50) {
    //   $("section#thatsus .member:nth-child(1)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 100) {
    //   $("section#thatsus .member:nth-child(2)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 150) {
    //   $("section#thatsus .member:nth-child(3)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 200) {
    //   $("section#thatsus .member:nth-child(4)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 250) {
    //   $("section#thatsus .member:nth-child(5)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 300) {
    //   $("section#thatsus .member:nth-child(6)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 350) {
    //   $("section#thatsus .member:nth-child(7)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 400) {
    //   $("section#thatsus .member:nth-child(8)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 450) {
    //   $("section#thatsus .member:nth-child(9)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 500) {
    //   $("section#thatsus .member:nth-child(10)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 550) {
    //   $("section#thatsus .member:nth-child(11)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 600) {
    //   $("section#thatsus .member:nth-child(12)").addClass("active");
    // }

    // if (topOffset > targetThatsus + 1000) {
    //   $("section#thatsus .section_con").addClass("move");
    // }
  }
  

  

  // SCROLL ANIMATIONS SECTION THATSUS
  $('.member').each( function(i){
    var bottom_of_element = $(this).offset().top + $(this).outerHeight();
    var bottom_of_window = $(window).scrollTop() + $(window).height();
    
    console.log('element' + bottom_of_element);
    console.log('window' + bottom_of_window);

    if( bottom_of_window > bottom_of_element + ($(window).height()/2 - $(this).outerHeight()) ){
      $(this).css({
        'opacity':'1',
        'transform':'scale(1)',
      });
    }
  });

  // SCROLL ANIMATIONS SECTION PART
  $('.logo_item').each( function(i){
    var bottom_of_element = $(this).offset().top + $(this).outerHeight();
    var bottom_of_window = $(window).scrollTop() + $(window).height();
    
    console.log('element' + bottom_of_element);
    console.log('window' + bottom_of_window);

    if( bottom_of_window > bottom_of_element + ($(window).height()/2 - $(this).outerHeight()) ){
      $(this).css({
        'opacity':'1',
        'transform':'scale(1)',
      });
    }
  });
});


$(window).on('scroll', function() {
  // if ($(window).scrollTop() >= $('section').offset().top + $('section').outerHeight() - window.innerHeight) {
  if ($(window).scrollTop() > $('section#intro').outerHeight() - 40) {
    $("body").addClass('scrolled');
    // console.log('You reached the end of the DIV');
  } else {
    $("body").removeClass('scrolled');
  }
});

// if ($("section#projects").hasClass("active")) {
//   projects_scroll();
// }

// function projects_scroll() {

//   // SCROLL ANIMATIONS SECTION PROJECTS
//   let panels = gsap.utils.toArray(".panel");

//   gsap.to(panels, {
//     xPercent: -100 * (panels.length - 1),
//     ease: "none",
//     scrollTrigger: {
//       start: 0,
//       end: st => ScrollTrigger.maxScroll(st.scroller),
//       scrub: 1,
//       snap: 1 / (panels.length - 1),
//       // base vertical scrolling on how wide the container is so it feels more natural.
//     }
//   });
// };